<template>
  <ClientOnly>
    <v-layout>
      <VMain>
        <slot />
      </VMain>
    </v-layout>
  </ClientOnly>
</template>

<script setup>
useHead({
  htmlAttrs: {
    lang: "ko",
  },
});
</script>
